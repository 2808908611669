export const createSellerInfo = ({
                                   sellerCompanyId = 0,
                                   sellerEmployeeId = 0,
                                   priceNet = 0,
                                   currencyId = 0
                                 }) => {
  return ({
    sellerCompanyId,
    sellerEmployeeId,
    priceNet,
    currencyId
  });
};
