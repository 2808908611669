import React from 'react';
import {Container} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {route} from "app/config/urls/routes";

export const ViewErr401 = () => {
  const {t} = useTranslation()
  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center text-center bg-theme px-3 py-5 rounded-lg">
      <h1 className="display-3 font-weight-bold mt-5">
        {t('generic.errorPageTitle')}
      </h1>
      <h1 className="mb-3 font-weight-normal">
        {t('generic.pageUnauthorized')}
      </h1>
      <Link to={route['/']}>
        <button className="btn btn-primary btn-lg px-10 mb-5">
          {t('generic.goBackIndex')}
        </button>
      </Link>
    </Container>
  );
};
