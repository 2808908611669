import {
  MESSAGES_SET_UNREAD,
  NOTIFICATIONS_SET_UNREAD,
  SET_MENU_DATA
} from "app/redux/reducers/notificationsAndMessages/actionTypes";

const initialState = () => ({
  conversations: {
    hasUnread: false,
    unreadCount: 0,
  },
  notifications: {
    unreadCount: 0,
  },
});


export const notificationReducer = (state = initialState(), {type, payload}) => {

  switch (type) {
    case SET_MENU_DATA: {
      const {unreadMessages, unreadNotifications} = payload;
      return {
        ...state,
        conversations: {
          ...state.conversations,
          unreadCount: unreadMessages,
        },
        notifications: {
          ...state.notifications,
          unreadCount: unreadNotifications,
        }
      }
    }
    case MESSAGES_SET_UNREAD: {
      const {hasUnread, unreadCount} = payload;
      return {
        ...state,
        conversations: {
          ...state.conversations,
          hasUnread,
          unreadCount,
        }
      }
    }
    case NOTIFICATIONS_SET_UNREAD: {
      const {unreadCount} = payload;
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unreadCount,
        }
      }
    }
    default: {
      return state;
    }
  }
}
