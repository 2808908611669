import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/fuel-card`;

//pc = panel.company

export const fuelCardsRoutes = {
  'pc.fuelCard': `${prefix}`,
  'pc.fuelCard.add': `${prefix}/add`,
  'pc.fuelCard.show': (id = ':fuelCardId') => `${prefix}/${id}`,
  'pc.fuelCard.edit': (id = ':fuelCardId') => `${prefix}/${id}/edit`,
}
