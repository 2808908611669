import {orderPlacesError} from "app/redux/editOrder/reducers/_error";
import {ORDER_PLACES_ERROR__MISSING_PLACE} from "app/redux/editOrder/actionTypes";

export const updateSinglePlace = (state, {placeId, key, value}) => {

  if (!state.orderPlaces[placeId]) {
    return orderPlacesError(state, {errCode: ORDER_PLACES_ERROR__MISSING_PLACE})
  }

  //TODO: add key validation (or not?)

  return {
    ...state,
    orderPlaces: {
      ...state.orderPlaces,
      [placeId]: {
        ...state.orderPlaces[placeId],
        [key]: value,
      },
    },
  };
}
