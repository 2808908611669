import React from "react";
import * as Icon from "react-feather";
import {route} from "app/config/urls/routes";
import {
  PERMISSION_ALL,
  PERMISSION_COMPANY, PERMISSION_COMPANY_ADMIN,
  PERMISSION_EURO24, PERMISSION_EURO24ADMIN
} from "app/config/permissions";

const navigationConfig = (t = () => "") => [
  // {
  //   id: "home",
  //   title: t("navigation_left.dashboard"),
  //   type: "item",
  //   icon: <Icon.Home size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.dashboard"],
  // },
  // {
  //   id: "messages",
  //   title: t("navigation_left.messages"),
  //   type: "item",
  //   icon: <Icon.Mail size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.conversations"],
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: t("navigation_left.transport"),
  // },
  // {
  //   id: "invoice",
  //   title: t("navigation_left.invoices"),
  //   type: "item",
  //   icon: <Icon.FileText size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.invoice"],
  // },
  {
    id: "companies",
    title: t("navigation_left.companies"),
    type: "item",
    icon: <Icon.Briefcase size={20}/>,
    permissions: PERMISSION_EURO24,
    navLink: route["pc.company"],
  },
  {
    id: "carriers",
    title: t("navigation_left.map"),
    type: "item",
    icon: <Icon.Compass size={20}/>,
    permissions: PERMISSION_EURO24,
    navLink: route["pc.carriers"],
  },
  // {
  //   type: "groupHeader",
  //   groupTitle: t("navigation_left.fleet"),
  // },
  {
    id: "vehicles",
    title: t("navigation_left.vehicles"),
    type: "item",
    icon: <Icon.Truck size={20}/>,
    permissions: PERMISSION_COMPANY,
    navLink: route["pc.vehicle"],
  },
  {
    id: "availableVehicles",
    title: t("navigation_left.availableVehicles"),
    type: "item",
    icon: <Icon.Truck size={20}/>,
    permissions: PERMISSION_COMPANY,
    navLink: route["pc.availableVehicles"],
  },
  // {
  //   id: "map",
  //   title: t("navigation_left.map"),
  //   type: "item",
  //   icon: <Icon.Map size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.map"],
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: t("generic.company"),
  // },
  {
    id: "employees",
    title: t("navigation_left.employees"),
    type: "item",
    icon: <Icon.Users size={20}/>,
    permissions: [PERMISSION_EURO24ADMIN, PERMISSION_COMPANY_ADMIN],
    navLink: route["pc.employee"],
  },
  // {
  //   id: "drivers",
  //   title: t("navigation_left.drivers"),
  //   type: "item",
  //   icon: <Icon.Users size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.employee.driver"],
  // },
  // {
  //   id: "company",
  //   title: t("navigation_left.myCompany"),
  //   type: "item",
  //   icon: <Icon.Layout size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.myCompany"],
  // },
  // {
  //   type: "groupHeader",
  //   groupTitle: "---",
  // },
  // {
  //   id: "settings",
  //   title: t("navigation_left.settings"),
  //   type: "item",
  //   icon: <Icon.Settings size={20}/>,
  //   permissions: PERMISSION_ALL,
  //   navLink: route["pc.settings"],
  // },
  // {
  //   id: "reportError",
  //   title: t("navigation_left.reportBug"),
  //   type: "item",
  //   icon: <Icon.AlertTriangle size={20} />,
  //   permissions: [
  //     PERMISSION_ADMIN,
  //     PERMISSION_COMPANY,
  //     PERMISSION_COMPANY_ADMIN,
  //     PERMISSION_EMPLOYEE,
  //   ],
  //   navLink: route["pc.reportBug"],
  // },
];

export default navigationConfig;
