import useObjectState from "app/hooks/useObjectState";


export const useModal = () => {
  const [{isOpen, param}, setState] = useObjectState({isOpen: false, param: null});

  const openModal = (param = null) => setState({isOpen: true, param});
  const closeModal = () => setState({isOpen: false, param: null});

  return [isOpen, openModal, closeModal, param];
}

export const useModalObj = () => {
  const [isOpen, open, close] = useModal();

  return {
    isOpen,
    open,
    close,
  }
}
