import {combineReducers} from "redux"
import {login} from "app/redux/reducers/auth/loginReducer"
import storage from 'redux-persist/lib/storage'
import {persistReducer} from "redux-persist";

const persistConfig = {
  key: 'euro24.auth.token',
  storage,
  whitelist: ['token', 'language'],
}

const authReducers = combineReducers({
  login: persistReducer(persistConfig, login),
})

export default authReducers
