import React, {useRef, useState} from 'react'
import {useModal} from 'app/hooks/useModal';
import {ImageMessageModal} from './ImageMessageModal';

export const ImageMessage = ({fileUrl, messageId}) => {

  const [isOpenModal, openModal, closeModal] = useModal();
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => setIsLoaded(true);
  const imgRef = useRef(null);
  return (
    <>
      <div className='cursor-pointer d-inline-flex'
           style={{
             height: isLoaded ? imgRef?.current?.height : '300px'
           }}
           onClick={openModal}>
        <div>
          <img src={fileUrl} ref={imgRef} className="chat-message-image" onLoad={handleImageLoad}/>
        </div>
        {isOpenModal && <ImageMessageModal
          id={messageId}
          isOpenModal={isOpenModal}
          closeModal={closeModal}
        />}
      </div>
    </>
  )
}
