import React from "react"
import ScrollToTop from "react-scroll-up"
import {Button} from "reactstrap"
import AppleStore from '@assets/img/app-store/app-store-black.png';
import GoogleStore from '@assets/img/app-store/google-play-black.png';
import EUFooter from '@assets/img/eu/eu-footer.png';
import styled from "styled-components";
import {ArrowUp, Facebook, Instagram, Linkedin} from "react-feather";
import {Link} from "react-router-dom";
import {route} from "app/config/urls/routes";

export const EUFlag = styled.img`
  max-width: 500px;
  width:100%;
  display:block;
  margin: 0 auto;
`;

const PublicFooter = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="bg-dark text-white">
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15}/>
          </Button>
        </ScrollToTop>

        <div className="container py-2">
          <div className="row">
            <div className="col-12 col-lg-4 text-center text-lg-left">
              <div className="d-flex justify-content-center justify-content-lg-start mb-1">
                <div className="logo"><img src="https://api.sydig.com/images/logo/logo-s.png" alt=""/></div>
                <div className="brand-text logo">
                  <img src="https://api.sydig.com/images/logo/logo-ydig.png" alt=""/>
                </div>
              </div>
              <p className="mb-0">
                &copy; {year} EURO24 SP. Z O. O.
              </p>
              <p>
                Made with <span role="img" aria-label="red heart">❤️</span> in Rzeszów
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 justify-content-center d-flex">
              <div className="d-flex flex-column">
                <h3 className="mb-1 mt-3 text-white">Informacje</h3>
                <Link to={route['public.regulations']}>Regulamin</Link>
                <Link to={route['public.privacyPolicy']}>Polityka prywatności</Link>
                <Link to={route['public.pricing']}>Cennik</Link>
                {/* <Link to={route['public.faq']}>FAQ</Link> */}
                <Link to={route['public.contact']}>Kontakt</Link>
                <Link to={route['public.euProject']}>Projekt UE</Link>
                <div className="mt-1">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/sydigcom"
                     className="btn btn-facebook p-1 rounded-circle">
                    <Facebook size="1.5em"/>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href={'https://www.instagram.com/sydigcom/'}
                     className="btn btn-instagram p-1 rounded-circle ml-1">
                    <Instagram size="1.5em"/>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href={'https://www.linkedin.com/company/sydigcom/'}
                     className="btn btn-linkedin p-1 rounded-circle ml-1">
                    <Linkedin size="1.5em"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 justify-content-center d-flex">
              <div className="d-flex flex-column">
                <h3 className="mb-1 mt-3 text-white">Aplikacja</h3>
                <a target="_blank" rel="noopener noreferrer"
                   href={'https://play.google.com/store/apps/details?id=com.sydig.sydig'}>
                  <img
                    style={{maxWidth: '170px'}}
                    src={GoogleStore}
                    alt="download from google play banner"
                    className="mb-1 cursor-pointer"
                  />
                </a>
                <a target="_blank" rel="noopener noreferrer" href={'https://apps.apple.com/us/app/sydig/id1540070483'}>
                  <img
                    style={{maxWidth: '170px'}}
                    src={AppleStore}
                    alt="download from app store banner"
                    className="cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-1 bg-white">
        <div className="container">
          <EUFlag src={EUFooter} alt="Unia Europejska - stopka"/>
        </div>
      </div>
    </>
  );
}

export default PublicFooter
