import PropTypes from 'prop-types';
import {useQuery} from "react-query";

const extractData = (data, level = 2, defaultData = {}) => {
  // level 1
  if (!data) return defaultData;
  else if (level === 1) return data;
  // level 2
  if (!data.data) return defaultData;
  else if (level === 2) return data.data;
  // level 3
  if (!data.data.data) return defaultData;
  else if (level === 3) return data.data.data;
  // default
  return defaultData;
};

const useQuery2 = (
  {
    queryKey,
    queryFn,
    config,
    extractLevel = 2,
    isArray = false,
    isHTML = false,
  }
) => {

  const {
    data: queryData,
    isFetching,
    error,
    status,
    failureCount,
    isStale,
    refetch,
    ...rest
  } = useQuery(
    queryKey,
    () => queryFn(...queryKey),
    config,
  );

  const loadingInfo = {status, error, isFetching};

  const extractedData = extractData(queryData, extractLevel, isArray ? [] : (isHTML ? '' : {}));

  return {
    data: extractedData,
    loadingInfo,
    isFetching,
    error,
    status,
    failureCount,
    isStale,
    refetch,
    ...rest,
  };
}

useQuery2.propTypes = {
  queryFn: PropTypes.func.isRequired,
  queryKey: PropTypes.array.isRequired,
}

export default useQuery2;
