import {orderRoutes} from "app/config/urls/companyPanel/orders";
import {companyRoutes} from "app/config/urls/companyPanel/companies";
import {vehicleRoutes} from "app/config/urls/companyPanel/vehicles";
import {employeeRoutes} from "app/config/urls/companyPanel/employees";
import {invoiceRoutes} from "app/config/urls/companyPanel/invoices";
import {dashboardRoutes} from "app/config/urls/companyPanel/dashboard";
import {COMPANY_PANEL} from "app/config/urls/prefixes";
import {myCompanyRoutes} from "app/config/urls/companyPanel/company";
import {settingsRoutes} from "app/config/urls/settings";
import {conversationRoutes} from "app/config/urls/companyPanel/conversations";
import {mapRoutes} from "app/config/urls/companyPanel/maps";
import {statsRoutes} from "app/config/urls/companyPanel/stats";
import {fuelCardsRoutes} from "app/config/urls/companyPanel/fuelCard";
import {leaseRoutes} from "app/config/urls/companyPanel/leases";
import {exchangeRoutes} from "app/config/urls/companyPanel/exchange";
import {companyGroupRoutes} from "app/config/urls/companyPanel/companyGroups";
import {myExchangesRoutes} from "app/config/urls/companyPanel/myExchanges";
import {carrierRoutes} from "./carriers";
import {availableVehiclesRoutes} from "./availableVehicles";

export const companyPanelRoutes = {
  pc: `${COMPANY_PANEL}`,
  //
  ...orderRoutes,
  ...companyRoutes,
  ...companyGroupRoutes,
  ...vehicleRoutes,
  ...fuelCardsRoutes,
  ...leaseRoutes,
  ...employeeRoutes,
  ...invoiceRoutes,
  ...dashboardRoutes,
  ...myCompanyRoutes,
  ...mapRoutes,
  ...statsRoutes,
  ...settingsRoutes,
  ...conversationRoutes,
  ...exchangeRoutes,
  ...myExchangesRoutes,
  ...carrierRoutes,
  ...availableVehiclesRoutes,
};
