import React, {useContext} from "react";

const QueryContext = React.createContext(null);

export const ProviderQueryContext = ({children, data, refetch, isFetching, status, error, pagination, hasNoData}) => {
  return (
    <QueryContext.Provider value={{data, refetch, isFetching, status, error, pagination, hasNoData}}>
      {children}
    </QueryContext.Provider>
  );
}

export const useQueryData = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('You forgot to use Query2Provider or PaginatedQuery2Provider :)')
  }
  return {
    data: context?.data,
    refetch: context?.refetch,
  };
}

export const useQueryState = () => {
  const context = useContext(QueryContext);
  if (!context) {
    throw new Error('You forgot to use Query2Provider or PaginatedQuery2Provider :)')
  }
  return {
    isFetching: context?.isFetching,
    status: context?.status,
    error: context?.error,
    hasNoData: context?.hasNoData,
    pagination: context?.pagination,
    isSuccess: context?.status === 'success',
  };
}
