import {BASE_URL} from "app/config/env";

export const publicRoutes = {
  'public.privacyPolicy': `${BASE_URL}/privacy-policy`,
  'public.regulations': `${BASE_URL}/user-agreement`,
  'public.euProject': `${BASE_URL}/eu-project`,
  'public.contact': `${BASE_URL}/contact`,
  'public.pricing': `${BASE_URL}/pricing`,
  'public.faq': `${BASE_URL}/faq`,
  'public.app': `${BASE_URL}/app`,
  'public.company': `${BASE_URL}/companies`,
  'public.company.show': (id = ':companyId') => `${BASE_URL}/companies/${id}`,
  'public.poster': `${BASE_URL}/posters`,
  'public.poster.show': (id = ':posterId') => `${BASE_URL}/posters/${id}`,
  'public.test': `${BASE_URL}/test`
}
