import {toast} from "react-toastify";


export const getFileNewTab = (t) => (token, url) => {
  let newUrl = url.split('://');
  let urlTab = url?.split('/');
  newUrl[1] = newUrl[1].replace('\/\/', '\/');
  return fetch(
    newUrl.join('://'),
    {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  )
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(async res => ({
        data: await res.blob(),
        type: res.headers.get('content-type')
      })
    )
    .then(({data, type}) => {
      console.log(data)
      const blob = new Blob([data], {type});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', "_blank");
      link.setAttribute('rel', "noopener noreferrer");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      console.log(url)
    })
    .catch(error => {
      toast.error(t('generic.fileDownloadError'))
    });
  // return axios({
  //   method: 'GET',
  //   url: newUrl.join('://'),//`${BASE_URL_API}/v1/company-panel/order-contracts/${orderContractId}/download-pdf`,
  //   config: {
  //     responseType: 'blob'
  //   }
  // }).then(({data})=>{
  //   const blob = new Blob([data], {type:fileType, endings: 'native'});
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', fileName);  // 3. Append to html page
  //   document.body.appendChild(link);  // 4. Force download
  //   link.click();  // 5. Clean up and remove the link
  //   link.parentNode.removeChild(link);
  // });
}
