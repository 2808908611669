export const updateLastMessage = (msg1, msg2) => {

  if (!msg2 || !msg2.createdAt) return msg1;
  if (!msg1 || !msg1.createdAt) return msg2;

  const time1 = new Date(msg1.createdAt).getTime();
  const time2 = new Date(msg2.createdAt).getTime();

  return time1 > time2 ? msg1 : msg2;
}
