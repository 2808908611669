import React, {useEffect, useState} from "react";
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import {useDispatch, useSelector} from "react-redux";
import {loginWithEmail, logout,} from "app/redux/actiontypes/auth/loginActions";
import {me} from "app/crud/auth/me";
import {ConfirmProvider} from "app/context/ConfirmProvider";
import {NotificationProvider} from "app/context/NotificationProvider";
import {ToastContainer} from "react-toastify";
import {ThemeProvider} from "styled-components";
import {useSelectorAuth} from "app/redux/actiontypes/auth/authSelectors";
import {useHistory} from "react-router-dom";
import {route} from "app/config/urls/routes";
import {QueryClient, QueryClientProvider} from "react-query";
import {useCookies} from "react-cookie";

const queryClient = new QueryClient();

export const Euro24Provider = ({children}) => {
  const [hasTriedLogin, setHasTriedLogin] = useState(false);
  const {token} = useSelector(({auth}) => auth.login);
  const dispatch = useDispatch();
  const theme = useSelector(({customizer}) => customizer?.customizer?.theme);
  const {userRoles} = useSelectorAuth();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(["token"]);
  useEffect(() => {
    const tryLogin = async () => {
      if (token) {
        try {
          const response = await me();
          if (!response?.data?.data)
            throw new Error("Logowanie nie powiodło się");
          const {
            id,
            name,
            surname,
            profilePhotoUrl,
            username,
            roles,
            defaultLanguageCode,
            defaultLanguageId,
            companyId,
            hasLimitedAccess,
            accesses,
          } = response.data.data;
          const nick =
            name || surname ? `${name ?? ""} ${surname ?? ""}` : username;
          setCookie("token", token, {path: "/", maxAge: 70000});
          dispatch(
            loginWithEmail(
              id,
              token,
              roles,
              nick,
              username,
              profilePhotoUrl,
              defaultLanguageId,
              defaultLanguageCode,
              companyId,
              hasLimitedAccess,
              accesses,
            ),
          );
        } catch (e) {
          //console.log(e);
          dispatch(logout());
        } finally {
          setHasTriedLogin(true);
        }
      } else {
        setHasTriedLogin(true);
      }
    };
    // noinspection JSIgnoredPromiseFromCall
    tryLogin();
  }, []);

  useEffect(() => {
    if (userRoles.length === 1 && userRoles[0] === "Driver") {
      history.replace(route["public.app"]);
      dispatch(logout());
    }
  }, [userRoles]);

  return hasTriedLogin ? (
    <ThemeProvider theme={{theme}}>
      <QueryClientProvider client={queryClient}>
        <ConfirmProvider>
          <NotificationProvider>
            {children}
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </NotificationProvider>
        </ConfirmProvider>
      </QueryClientProvider>
    </ThemeProvider>
  ) : (
    <Spinner/>
  );
};
