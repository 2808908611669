export const orderPlacesError = (state, {errCode, id: passedId}) => {
  const id = passedId ?? `_${state.helpers.counter}`;
  return {
    ...state,
    errors: {
      [id]: {
        errCode,
      },
    },
  };
}
