import {BASE_URL} from "app/config/env";
import {companyPanelRoutes} from "app/config/urls/companyPanel";
import {specialRoutes} from "app/config/urls/specialRoutes";
import {publicRoutes} from "./publicRoutes";

export const route = {
  '/': `${BASE_URL}/`,
  ...publicRoutes,
  ...companyPanelRoutes,
  ...specialRoutes,
};
