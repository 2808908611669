export const createPlaceElement = ({
                                     id = null,
                                     placeType = 1,
                                     refNo = '',
                                     addressId = '',
                                     addressComment = '',
                                     addressCompanyName = '',
                                     description = '',
                                     dateFrom = null,
                                     dateTo = null,
                                   }) => {
  return ({
    id,
    placeType,
    refNo,
    addressId,
    addressComment,
    addressCompanyName,
    description,
    dateFrom,
    dateTo,
  });
};
