import {
  ORDER__DELETE_FILLED_PERCENTAGE,
  ORDER__SET_FILLED_PERCENTAGE,
  ORDER_PLACES__CLEAR,
  ORDER_PLACES__DELETE_CARGO,
  ORDER_PLACES__DELETE_PLACE,
  ORDER_PLACES__SET_ALL_CARGOS,
  ORDER_PLACES__SET_ALL_PLACES,
  ORDER_PLACES__SET_CARGO,
  ORDER_PLACES__SET_PLACE,
  ORDER_PLACES__UPDATE_CARGO,
  ORDER_PLACES__UPDATE_PLACE,
  ORDER_SELLER__DELETE_INFO,
  ORDER_SELLER__SET_INFO,
  ORDER_SELLER__UPDATE_INFO
} from "app/redux/editOrder/actionTypes";
import {setAllPlaces} from "app/redux/editOrder/reducers/places.setAll";
import {setAllCargos} from "app/redux/editOrder/reducers/cargos.setAll";
import {setSinglePlace} from "app/redux/editOrder/reducers/places.setSingle";
import {setSingleCargo} from "app/redux/editOrder/reducers/cargos.setSingle";
import {updateSinglePlace} from "app/redux/editOrder/reducers/places.update";
import {updateSingleCargo} from "app/redux/editOrder/reducers/cargos.update";
import {deleteSinglePlace} from "app/redux/editOrder/reducers/places.delete";
import {deleteSingleCargo} from "app/redux/editOrder/reducers/cargos.delete";
import {deleteFilledPercentage} from "./reducers/percentage.delete";
import {setFilledPercentage} from "./reducers/percentage.set";
import {deleteSellerInfo} from "./reducers/sellerInfo.delete";
import {setSellerInfo} from "./reducers/sellerInfo.set";
import {updateSellerInfo} from "./reducers/sellerInfo.update";

const initialState = () => ({
  orderPlaces: {},
  orderCargos: {
    // 1: createPlaceCargo({id:1, placeLoadingId: 229, placeUnloadingId: 228}),
    // 2: createPlaceCargo({id:2, placeLoadingId: 229, placeUnloadingId: 231}),
    // 3: createPlaceCargo({id:3, placeLoadingId: 229, placeUnloadingId: 232}),
    // 4: createPlaceCargo({id:4, placeLoadingId: 230, placeUnloadingId: 228}),
    // 5: createPlaceCargo({id:5, placeLoadingId: 230, placeUnloadingId: 231}),
    // 6: createPlaceCargo({id:6, placeLoadingId: 230, placeUnloadingId: 232}),
  },
  orderPlacesKeys: [],
  orderCargosKeys: [],
  errors: {},
  helpers: {
    counter: 0,
    cargoPlacesUpdates: 0,
  },
  sellerInfo: {
    sellerCompanyId: 0,
    sellerEmployeeId: 0,
    priceNet: 0,
    currencyId: 0
  },
  filledPercentage: null
});

export const editOrderReducer = (state = initialState(), {type, payload}) => {
  switch (type) {

    // init
    case ORDER_PLACES__CLEAR: {
      return initialState();
    }

    // set all
    case ORDER_PLACES__SET_ALL_PLACES: {
      return setAllPlaces(state, payload);
    }
    case ORDER_PLACES__SET_ALL_CARGOS: {
      return setAllCargos(state, payload);
    }

    // set single
    case ORDER_PLACES__SET_PLACE: {
      return setSinglePlace(state, payload);
    }
    case ORDER_PLACES__SET_CARGO: {
      return setSingleCargo(state, payload);
    }

    // update single
    case ORDER_PLACES__UPDATE_PLACE: {
      return updateSinglePlace(state, payload);
    }
    case ORDER_PLACES__UPDATE_CARGO: {
      return updateSingleCargo(state, payload);
    }

    // delete single
    case ORDER_PLACES__DELETE_PLACE: {
      return deleteSinglePlace(state, payload);
    }
    case ORDER_PLACES__DELETE_CARGO: {
      return deleteSingleCargo(state, payload);
    }

    // filled percentageg
    case ORDER__DELETE_FILLED_PERCENTAGE: {
      return deleteFilledPercentage(state, payload);
    }
    case ORDER__SET_FILLED_PERCENTAGE: {
      return setFilledPercentage(state, payload);
    }
    case ORDER_SELLER__DELETE_INFO: {
      return deleteSellerInfo(state, payload);
    }
    case ORDER_SELLER__SET_INFO: {
      return setSellerInfo(state, payload);
    }
    case ORDER_SELLER__UPDATE_INFO: {
      return updateSellerInfo(state, payload);
    }

    default: {
      return state;
    }
  }
}
