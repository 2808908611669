import {createChat} from "app/redux/chats&notifications/utlis/createChat";

export const reducerUpdateChat = (state, {chat}) => {

  if (!chat.id) {
    // error new chat state not exist
    return state;
  }

  if (!state?.chats?.[chat.id]) {
    // error chat not exist
    return state;
  }

  const {messages, stats, newMessage, fileMessage, ...newChat} = createChat(chat);

  return {
    ...state,
    chats: {
      ...state.chats,
      [chat.id]: {
        ...state.chats[chat.id],
        ...newChat,
      },
    }
  }
}
