import axios from 'axios';
import {BASE_URL_API} from "app/config/env";
import useQuery2 from "app/hooks/useQuery2";

const getAvailableLanguages = () => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/translations/languages`,
});

export const useGetAvailableLanguages = () => useQuery2({
  queryKey: ['list.translations'],
  queryFn: getAvailableLanguages,
});
