import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/companies`;

// pc = panel.company

export const companyRoutes = {
  'pc.company': `${prefix}`,
  'pc.company.add': `${prefix}/add`,
  'pc.company.show': (id = ':companyId') => `${prefix}/${id}`,
  'pc.company.edit': (id = ':companyId') => `${prefix}/${id}/edit`,
  // company employee
  'pc.company.employee.show': (companyId = ':companyId', id = ':employeeId') => `${prefix}/${companyId}/employees/${id}`,
  'pc.company.employee.edit': (companyId = ':companyId', id = ':employeeId') => `${prefix}/${companyId}/employees/${id}/edit`,
  'pc.company.vehicle.show': (companyId = ':companyId', id = ':vehicleId') => `${prefix}/${companyId}/vehicles/${id}`,
  'pc.company.vehicle.edit': (companyId = ':companyId', id = ':vehicleId') => `${prefix}/${companyId}/vehicles/${id}/edit`,
}
