import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/vehicles`;

//pc = panel.company

export const vehicleRoutes = {
  'pc.vehicle': `${prefix}`,
  'pc.vehicle.add': `${prefix}/add`,
  'pc.vehicle.show': (id = ':vehicleId') => `${prefix}/${id}`,
  'pc.vehicle.edit': (id = ':vehicleId') => `${prefix}/${id}/edit`,
}
