export const createChatMessage = ({
                                    id,
                                    createdAt,
                                    message,
                                    isLoggedEmployeeSender,
                                    isRead,
                                    messageType,
                                    fileUrl,
                                    nextMessagePhotoId,
                                    prevMessagePhotoId
                                  }) => ({
  id,
  createdAt,
  message,
  isMine: isLoggedEmployeeSender,
  isRead,
  messageType,
  fileUrl,
  nextMessagePhotoId,
  prevMessagePhotoId
});
