import React, {lazy} from "react"
import {Redirect, Route} from "react-router-dom"
import {ViewErr404} from "views/pages/error/View_404";
import {authRoutes} from "views/pages/authentication/Routes/Routes_Auth";
import {route} from "app/config/urls/routes";
import {AppSwitch} from "app/router/components/AppSwitch";
import "flatpickr/dist/themes/light.css";
import {PublicRoute} from "app/router/components/PublicRoute";
import {sharedRoutes} from "views/pages/shared/Routes/Routes_Shared";

// Route-based code splitting
const CompanyPanelRoutes = lazy(() => import('app/router/routes/RootRoutes_CompanyPanel'));
const RedirectLogin = () => <Redirect to={route["auth.login"]}/>

const AppRouter = () => {

  return (
    // Set the directory path if you are deploying in sub-folder
    <AppSwitch>
      {/* COMPANY PANEL: */}
      <Route
        exact
        path={route['/']}
        component={RedirectLogin}
      />
      <Route
        path={route['pc']}
        component={CompanyPanelRoutes}
      />

      {/* AUTH: */}
      {authRoutes}

      {/* SHARED: */}
      {sharedRoutes}

      {/* 404: */}
      <PublicRoute component={ViewErr404}/>
    </AppSwitch>
  )
}

export default AppRouter
