import {useSelector} from "react-redux";
import {useChatContext} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";


export const useSelectChatMessages = () => {
  const activeChatId = useChatContext()
  return useSelector(({chats: {chats, chatMessages}}) => ({
    employee: activeChatId ? (chats?.[activeChatId]?.employee ?? {}) : ({}),
    messages: activeChatId ? (chatMessages[activeChatId] ?? {}) : ({}),
  }))
}
