import React from "react"
import Router from "app/router/Router"
import {Euro24Provider} from "app/context/Euro24Provider";
import './i18n';
import {CookiesProvider} from 'react-cookie';
// css
import "./components/@vuexy/rippleButton/RippleButton"
import "react-perfect-scrollbar/dist/css/styles.css"
import "prismjs/themes/prism-tomorrow.css"
import "flag-icon-css/css/flag-icon.min.css"
import 'react-toastify/dist/ReactToastify.css';
import {SignalRProvider} from "app/context/signalR/SignalRProvider";
import {I18nProvider} from "app/context/I18nProvider";

const queryConfig = {
  queries: {
    retry: false,
    refetchOnWindowFocus: false,
  }
}

const App = () => {
  return (
    <CookiesProvider>
      <Euro24Provider>
        <SignalRProvider>
          <I18nProvider/>
          <Router/>
        </SignalRProvider>
      </Euro24Provider>
    </CookiesProvider>
  );
}

export default App
