import {HttpTransportType, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {BASE_URL_API} from "app/config/env";

export const notificationHub = (tokenRef, token) => {
  return new HubConnectionBuilder()
    .withUrl(
      `${BASE_URL_API}/hubs/notifications`,
      {
        accessTokenFactory: () => tokenRef.current ?? token,
        withCredentials: false,
        transport: HttpTransportType.LongPolling,
      }
    )
    .configureLogging(LogLevel.Information)
    .build();
};

export const HUB_NOTIFICATIONS_RECEIVE_NOTIFICATION = 'ReceiveNotification';
