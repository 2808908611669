import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/my-company`;

// pc = panel.company

export const myCompanyRoutes = {
  'pc.myCompany': `${prefix}`,
  // 'pc.myCompany.bankAccounts': `${prefix}/bank-accounts`,
};
