import * as yup from 'yup';
import {setLocale} from 'yup';

export const setupYup = () => {

  yup.addMethod(yup.string, 'vatNo', function (message) {
    //console.log('a', message)
    return this.test('s', message, function (value) {
      //console.log(message, value);
      const {path, createError} = this;
      //console.log(message, path, createError, value);
      return [];
    });

  });
}

setLocale({
  mixed: {
    required: {'i18n': 'generic.requiredField'},
    notType: {'i18n': 'generic.requiredField'}, // https://github.com/jquense/yup/issues/394#issuecomment-596453493 - error description
  },
  string: {
    email: {'i18n': 'auth.invalidEmail'},
  },
})
