import React from 'react';
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import {ApiErrors} from "components/api/ApiErrors";
import {ProviderQueryContext} from "app/context/_QueryContextProvider";

export const Query2Provider = (
  {
    data,
    isFetching,
    error,
    status,
    children,
    errorRender,
    refetch,
    loadingComponent,
    errorComponent,
    ...rest
  }
) => {
  return (
    status !== 'success' ? (
      isFetching ? (
        loadingComponent ? loadingComponent : <Spinner/>
      ) : (
        errorComponent ? errorComponent : <ApiErrors error={error}/>
      )
    ) : (
      <ProviderQueryContext data={data} refetch={refetch}>
        {typeof children === 'function' ? (
          children(data, {isFetching, status, error, refetch, ...rest})
        ) : (
          children
        )}
      </ProviderQueryContext>
    )
  );
};
