export const ORDER_PLACES__CLEAR = 'orderPlaces.clear';

// set all
export const ORDER_PLACES__SET_ALL_PLACES = 'orderPlaces.places.setAll';
export const ORDER_PLACES__SET_ALL_CARGOS = 'orderPlaces.cargos.setAll';

// set single
export const ORDER_PLACES__SET_PLACE = 'orderPlaces.place.set';
export const ORDER_PLACES__SET_CARGO = 'orderPlaces.cargo.set';

// update single
export const ORDER_PLACES__UPDATE_PLACE = 'orderPlaces.place.update';
export const ORDER_PLACES__UPDATE_CARGO = 'orderPlaces.cargo.update';

// delete single
export const ORDER_PLACES__DELETE_PLACE = 'orderPlaces.place.delete';
export const ORDER_PLACES__DELETE_CARGO = 'orderPlaces.cargo.delete';

// error
export const ORDER_PLACES_ERROR__MISSING_PLACE = 'orderPlaces.error.place.missing';
export const ORDER_PLACES_ERROR__MISSING_CARGO = 'orderPlaces.error.cargo.missing';

//set % filled
export const ORDER__SET_FILLED_PERCENTAGE = 'order.filled.set'

//delete % filled
export const ORDER__DELETE_FILLED_PERCENTAGE = 'order.filled.delete'

//seller info
export const ORDER_SELLER__SET_INFO = "order.seller.set"
export const ORDER_SELLER__DELETE_INFO = "order.seller.delete"
export const ORDER_SELLER__UPDATE_INFO = "order.seller.update"

// // set cargos placeStart placeEnd
// const ORDER_PLACES__SET_CARGO_LOCATION = 'order.place.set.location';

export const dispatchSetAllPlaces = (orderPlaces) => ({
  type: ORDER_PLACES__SET_ALL_PLACES,
  payload: {
    orderPlaces,
  },
});

export const dispatchSetAllCargos = (orderCargos) => ({
  type: ORDER_PLACES__SET_ALL_CARGOS,
  payload: {
    orderCargos,
  },
});

export const dispatchSetPlace = (placeId, orderPlace) => ({
  type: ORDER_PLACES__SET_PLACE,
  payload: {
    placeId,
    orderPlace,
  },
});

export const dispatchSetCargo = (cargoId, orderCargo) => ({
  type: ORDER_PLACES__SET_CARGO,
  payload: {
    cargoId,
    orderCargo,
  },
});

export const dispatchDeletePlace = (placeId) => ({
  type: ORDER_PLACES__DELETE_PLACE,
  payload: {
    placeId,
  },
});

export const dispatchDeleteCargo = (cargoId) => ({
  type: ORDER_PLACES__DELETE_CARGO,
  payload: {
    cargoId,
  },
});

export const dispatchUpdatePlace = (placeId, key, value) => ({
  type: ORDER_PLACES__UPDATE_PLACE,
  payload: {
    placeId,
    key,
    value,
  },
});

export const dispatchUpdateCargo = (cargoId, key, value) => ({
  type: ORDER_PLACES__UPDATE_CARGO,
  payload: {
    cargoId,
    key,
    value,
  },
});

export const dispatchSetFilledPercentage = (value) => ({
  type: ORDER__SET_FILLED_PERCENTAGE,
  payload: value,
});

export const dispatchDeleteFilledPercentage = () => ({
  type: ORDER__DELETE_FILLED_PERCENTAGE,
});

export const dispatchSetSellerInfo = (value) => ({
  type: ORDER_SELLER__SET_INFO,
  payload: value,
});

export const dispatchDeleteSellerInfo = () => ({
  type: ORDER_SELLER__DELETE_INFO,
});

export const dispatchUpdateSellerInfo = (key, value) => ({
  type: ORDER_SELLER__UPDATE_INFO,
  payload: {
    key,
    value,
  },
});


