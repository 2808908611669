import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}`;

// pc = panel.company

export const settingsRoutes = {
  'pc.settings': `${prefix}/settings`,
  'pc.settings.password': `${prefix}/settings/change-password`,
  'pc.settings.notifications': `${prefix}/settings/notifications`,
  'pc.reportBug': `${prefix}/report-bug`
};
