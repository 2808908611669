import axios from 'axios';
import useQuery2 from "app/hooks/useQuery2";
import {BASE_URL_API} from "app/config/env";

const getInfo = () => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/my-account/menu-data`,
});

export const useMenuData = (enabled = true) => useQuery2({
  queryKey: ['globals.menuData'],
  queryFn: getInfo,
  config: {
    refetchInterval: 15000,
    enabled: false,
  }
});
