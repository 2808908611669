export const PERMISSION_ADMIN = 'Administrator';
export const PERMISSION_DEV = 'Dev';
export const PERMISSION_EURO24ADMIN = 'Euro24Admin';
export const PERMISSION_EURO24EMPLOYEE = 'Euro24Employee';
export const PERMISSION_COMPANY_ADMIN = 'CompanyAdmin';
export const PERMISSION_COMPANY_EMPLOYEE = 'Employee';

export const PERMISSION_ALL = [PERMISSION_EURO24ADMIN, PERMISSION_EURO24EMPLOYEE, PERMISSION_COMPANY_ADMIN, PERMISSION_COMPANY_EMPLOYEE];
export const PERMISSION_EURO24 = [PERMISSION_EURO24ADMIN, PERMISSION_EURO24EMPLOYEE];
export const PERMISSION_COMPANY = [PERMISSION_COMPANY_ADMIN, PERMISSION_COMPANY_EMPLOYEE];
