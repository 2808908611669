import {orderPlacesError} from "app/redux/editOrder/reducers/_error";
import {ORDER_PLACES_ERROR__MISSING_CARGO} from "app/redux/editOrder/actionTypes";

export const updateSingleCargo = (state, {cargoId, key, value}) => {

  if (!state.orderCargos[cargoId]) {
    return orderPlacesError(state, {errCode: ORDER_PLACES_ERROR__MISSING_CARGO})
  }

  //TODO: add key validation (or not?)

  return {
    ...state,
    orderCargos: {
      ...state.orderCargos,
      [cargoId]: {
        ...state.orderCargos[cargoId],
        [key]: value,
      },
    },
  };
}
