export const reducerSetNewTempMessage = (state, {chatId, newMessage}) => {

  return {
    ...state,
    chats: {
      ...state.chats,
      [chatId]: {
        ...state.chats[chatId],
        newMessage,
      }
    }
  }
}
