import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/lease-agreements`;

//pc = panel.company

export const leaseRoutes = {
  'pc.lease': `${prefix}`,
  'pc.lease.add': `${prefix}/add`,
  'pc.lease.show': (id = ':leaseId') => `${prefix}/${id}`,
  'pc.lease.edit': (id = ':leaseId') => `${prefix}/${id}/edit`,
}
