import React from 'react';
import styled from 'styled-components'
import {route} from "app/config/urls/routes";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useMatchPath from "app/hooks/useMatchPath";

const Container = styled.section`
  height: ${({isPanel}) => isPanel ? 'calc(100vh - 15rem)' : 'calc(100vh - 20rem)'};
`

export const ViewErr404 = () => {
  const {t} = useTranslation()
  const isPanel = useMatchPath({
    path: route['pc'],
    exact: false,
  })
  return (
    <Container isPanel={isPanel} className="d-flex flex-column justify-content-center align-items-center text-center">
      <h1 className="display-3 font-weight-bold">
        {t('generic.errorPageTitle')}
      </h1>
      <h1 className="mb-3 font-weight-normal">
        {t('generic.pageNotFound')}
      </h1>
      <Link to={isPanel ? route['pc.availableVehicles'] : route['/']}>
        <button className="btn btn-primary btn-lg px-10">
          {
            isPanel
              ? t('generic.goBackDashboard')
              : t('generic.goBackIndex')
          }
        </button>
      </Link>
    </Container>
  );
};
