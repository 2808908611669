import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from "dayjs/plugin/isoWeek"
import localizedFormat from "dayjs/plugin/localizedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import {useCallback} from "react";
import {useSelectorAuth} from "app/redux/actiontypes/auth/authSelectors";

import "dayjs/locale/pl"
import "dayjs/locale/en-gb"
import "dayjs/locale/de"
import "dayjs/locale/it"
import "dayjs/locale/fr"
import "dayjs/locale/es"
import "dayjs/locale/hu"
import "dayjs/locale/lt"
import "dayjs/locale/uk"

dayjs.extend(relativeTime)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

const localeCodes = {
  pl: 'pl',
  gb: 'en-gb',
  de: 'de',
  it: 'it',
  fr: 'fr',
  es: 'es',
  hu: 'hu',
  lt: 'lt',
  ua: 'uk',
}

const useMoment = () => {
  const {language} = useSelectorAuth();

  const code = language?.languageCode?.toLowerCase()
  const locale = localeCodes.hasOwnProperty(code) ? localeCodes[code] : localeCodes.gb

  return useCallback((...args) => dayjs(args).locale(locale), [locale])
}

export default useMoment
