import React, {useEffect, useRef} from 'react';
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import Spinner from "components/@vuexy/spinner/Loading-spinner";
import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import {useDispatch} from "react-redux";
import {useQueryClient} from "react-query";
import {actionSetMessages} from "app/redux/chats&notifications/chatActions/actionSetMessages";
import {Message} from "views/pages/companyPanel/conversations/View_Conversation/components/messages/Message";
import {useSelectChatMessages} from "app/redux/chats&notifications/selectors/selectChatMessages";
import useQuery2 from 'app/hooks/useQuery2';
import {useTranslation} from "react-i18next";
import {useChatContext} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import {actionClearUnread} from "app/redux/chats&notifications/chatActions/actionClearUnread";
import {getChat} from "app/crud/global/messages/getChat";
import {useSelectChatInfo} from "app/redux/chats&notifications/selectors/selectChatInfo";


export const MessageList = ({avatarSize}) => {
  const activeChatId = useChatContext()
  const {t} = useTranslation()
  const {messages, employee} = useSelectChatMessages();
  const messageContainerRef = useRef(null);
  const {chat} = useSelectChatInfo(activeChatId);
  const dispatch = useDispatch();
  const queryClient = useQueryClient()

  const getMessages = (key, chatId) => axios({
    method: 'GET',
    url: `${BASE_URL_API}/v1/company-panel/chats/${chatId}`,
    params: {
      page: 1,
      pageSize: 100,
      keepPreviousData: true,
    }
  }).then(({data}) => data);

  const {isFetching} = useQuery2({
    queryKey: ['pc.chats.messages2', activeChatId],
    queryFn: () => getMessages('pc.chats.messages2', activeChatId),
    config: {
      onSuccess: (e) => onSuccess(e),
      enabled: !!activeChatId,
      refetchOnWindowFocus: false,
    }
  });

  const onSuccess = ({chatMessages, ...chat}) => {
    dispatch(actionSetMessages(chat, chatMessages));
  }

  useEffect(() => {
    if (chat == null) return
    getChat('pc.chats.messages2', activeChatId).then(() => {
      dispatch(actionClearUnread(activeChatId))
      queryClient.invalidateQueries('globals.chats.count')
    })
  }, [chat?.unreadCount])

  useEffect(() => {
    if (messageContainerRef?.current) {
      //console.log(messageContainerRef.current.scrollTop, messageContainerRef.current.scrollHeight)
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="card-body p-0 overflow-auto position-relative">
      <div style={{height: '100%', overflowY: 'auto'}} ref={messageContainerRef}>
        <div className="px-6 py-50 w-100 left-0 bottom-0 d-flex flex-column-reverse">
          {messages && Object.keys(messages).length ? (
            Object.keys(messages)?.reverse()?.map((messageId) => (
              <Message
                key={messageId}
                profilePhotoUrl={employee?.photoUrl}
                avatarSize={avatarSize}
                {...messages[messageId]}
              />
            ))
          ) : (
            !isFetching ? (
              <div className="py-3 text-center">
                <Avatar size="xl" img={employee?.photoUrl}/>
                <h5>{employee?.firstName ?? ''} {employee?.lastName ?? ''}</h5>
                <p className="m-0 small text-muted">{t('message.noMessages1')}</p>
                <p className="m-0 small text-muted">{t('message.noMessages2')}</p>
              </div>
            ) : (
              <Spinner/>
            )
          )}
        </div>
      </div>
    </div>
  );
};
