import {useSignalRConnections} from "app/context/signalR/SignalRProvider";
import {useCallback, useEffect} from "react";
import {
  HUB_MESSAGES_DELETE_CONVERSATION_MESSAGE,
  HUB_MESSAGES_RECEIVE_CONVERSATION_MESSAGE,
  HUB_MESSAGES_SEND_CONVERSATION_FILE,
  HUB_MESSAGES_SEND_CONVERSATION_MESSAGE,
  hubMessagesSendConversationMessage
} from "app/context/signalR/hubs/messagesHub";
import {useSignalREvent} from "app/hooks/signalR/useSignalrEvent";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";


export const useMessagesHub = () => {
  const {t} = useTranslation()
  const hasRole = useSelector(({auth}) => !!auth.login?.userRoles?.length);

  // check if connection exists
  const connections = useSignalRConnections();
  if (hasRole && !connections?.current?.messagesHub) {
    throw new Error('Missing SignalRProvider');
  }
  useEffect(() => {
    if (!hasRole) return
    if (connections.current.messagesHub.connectionState === 'Disconnected') {
      connections.current.messagesHub.start().then(() => {
        console.log('connection started');
      });
    }
  }, [hasRole]);

  // send message helper
  const sendConversationMessage = useCallback(async (chatId = 27, message = '') => {
    if (!hasRole) return
    try {
      await connections.current.messagesHub.invoke(
        HUB_MESSAGES_SEND_CONVERSATION_MESSAGE,
        chatId,
        hubMessagesSendConversationMessage(message)
      );
    } catch (e) {
      toast.error(t('message.sendError'));
    }
  }, [hasRole]);

  // delete message helper
  const deleteConversationMessage = useCallback(async (messageId, deleteFor = 1,) => {
    if (!hasRole) return
    try {
      await connections.current.messagesHub.invoke(
        HUB_MESSAGES_DELETE_CONVERSATION_MESSAGE,
        messageId,
        {deleteFor}
      );
    } catch (e) {
      toast.error(t('message.deleteError'));
    }
  }, [hasRole]);

  const sendConversationFile = useCallback(async (chatId, data) => {
    if (!hasRole) return
    try {
      await connections.current.messagesHub.invoke(
        HUB_MESSAGES_SEND_CONVERSATION_FILE,
        parseInt(chatId),
        data
      );
    } catch (e) {
      toast.error('' + e);
    }
  }, [hasRole]);

  return [
    connections.current.messagesHub, // connection
    { // helpers:
      sendConversationMessage,
      deleteConversationMessage,
      sendConversationFile
    }
  ];
}

// getMessage observer
export const useMessagesHubReceiveMessage = (messagesConnection, callback) => {
  return useSignalREvent(messagesConnection, HUB_MESSAGES_RECEIVE_CONVERSATION_MESSAGE, callback);
}
