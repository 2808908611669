import React from 'react';
import {FormGroup, Label} from "reactstrap";

export const InputPureField = ({
                                 type = 'text',
                                 name = null,
                                 label = null,
                                 placeholder: passedPlaceholder,
                                 icon = null,
                                 iconSide = 'left',
                                 className = '',
                                 required,
                                 tag,
                                 value,
                                 onChange: handleChange,
                                 onEnter = () => {
                                 },
                                 ...props
                               }) => {

  const placeholder = passedPlaceholder ?? `${label ?? ''}${required ? '*' : ''}`;
  const Input = tag ?? 'input';
  const handleKeyDown = ({key}) => {
    if (key === 'Enter') onEnter();
  }
  return (
    <FormGroup
      className={`form-label-group position-relative mt-50 ${icon ? `has-icon-${iconSide}` : ''} ${className}`}>
      <Input
        className="form-control"
        type={type}
        name={name}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={({target}) => handleChange(target.value)}
        onKeyDown={handleKeyDown}
        {...props}
      />
      {icon && (
        <div className="form-control-position">
          {icon}
        </div>
      )}
      <Label>
        {label}
        {required && <span className="text-danger">*</span>}
      </Label>
      {/*<InputErrorMessage errors={error} name={name} />*/}
    </FormGroup>
  );
};
