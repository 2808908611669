import React from 'react';
import styled, {css} from "styled-components";
import {useTranslation} from "react-i18next";

const StyledDropzone = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0004;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  transition: opacity 0.25s ease;
  user-select: none;
  pointer-events: none;

  ${({isDragActive}) => (!isDragActive) && css`
    opacity: 0;
  `}

  > div {
    position: relative;
    margin: 2em;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
`

const MessageDropzone = ({isDragActive}) => {
  const {t} = useTranslation();

  return (
    <StyledDropzone {...{isDragActive}}>
      <div className="card">
        <span style={{fontSize: '1.5em'}}>
          {t('generic.fileUploadDrag')}
        </span>
      </div>
    </StyledDropzone>
  )
}

export default MessageDropzone
