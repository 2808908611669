import axios from "axios";
import {BASE_URL_API} from "app/config/env";
import useQuery2 from "app/hooks/useQuery2";

export const getChat = (key, id) => axios({
  method: 'GET',
  url: `${BASE_URL_API}/v1/company-panel/chats/${id}`,
});

export const useChat = (id) => useQuery2({
  queryKey: ['globals.chat', id],
  queryFn: getChat,
  config: {
    refetchInterval: 15000,
    enabled: !!id,
    keepPreviousData: true,
  }
});
