import React from 'react';
import PublicLayout from 'layouts/PublicLayout';
import {AppRoute} from 'app/router/components/AppRoute';
import PropTypes from 'prop-types';

const publicRouteRender = (Component, fullWidth) => (props) => (
  <PublicLayout fullWidth={fullWidth}>
    <Component {...props} />
  </PublicLayout>
);

export const PublicRoute = ({
                              component: Component,
                              fullWidth,
                              ...props
                            }) => (
  <AppRoute
    {...props} // can overwrite exact
    component={publicRouteRender(Component, fullWidth)}/>
);

PublicRoute.propTypes = {
  fullWidth: PropTypes.bool,
  component: PropTypes.any,
}
