import React from "react"
import {DropdownItem as DropdownItemBootstrap, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap"
import * as Icon from "react-feather"
import {Moon, Sun} from "react-feather"
import {Link} from "react-router-dom";
import {route} from "app/config/urls/routes";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Chats} from "layouts/includes/navbar/Chats";
import {changeMode} from "app/redux/actiontypes/customizer";
import LanguageSwitcher from "components/includes/LanguageSwitcher";

const DropdownItem = ({...props}) => (
  <DropdownItemBootstrap {...props} tag={Link}/>
);

const UserDropdown = () => {
  const {t} = useTranslation()
  return (
    <DropdownMenu right>
      {/*<DropdownItem tag="a" href="#">*/}
      {/*  <Icon.User size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">Edit Profile</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem tag="a" href="#">*/}
      {/*  <Icon.Mail size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">My Inbox</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem tag="a" href="#">*/}
      {/*  <Icon.CheckSquare size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">Tasks</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem tag="a" href="#">*/}
      {/*  <Icon.MessageSquare size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">Chats</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem tag="a" href="#">*/}
      {/*  <Icon.Heart size={14} className="mr-50" />*/}
      {/*  <span className="align-middle">WishList</span>*/}
      {/*</DropdownItem>*/}
      {/*<DropdownItem divider />*/}
      <DropdownItem to={route['pc.availableVehicles']}>
        <Icon.Zap size={14} className="mr-50"/>
        <span className="align-middle">{t('generic.panel')}</span>
      </DropdownItem>
      <DropdownItem to={route['pc.settings']}>
        <Icon.User size={14} className="mr-50"/>
        <span className="align-middle">{t('generic.myAccount')}</span>
      </DropdownItem>
      <DropdownItem to={route['auth.logout']}>
        <Icon.Power size={14} className="mr-50"/>
        <span className="align-middle">{t('auth.logout')}</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

const languages = {
  gb: 'EN',
  pl: 'PL',
  de: 'DE',
  fr: 'FR',
  es: 'ES',
  it: 'IT',
  hu: 'HU',
  lt: 'LT',
  ua: 'UA',
};

export const NavbarUser = ({className = '', ...props}) => {

  const {i18n} = useTranslation();
  const theme = useSelector(({customizer}) => customizer?.customizer?.theme);
  const dispatch = useDispatch();
  const toggleTheme = () => {
    dispatch(changeMode(theme === 'dark' ? 'semi-dark' : 'dark'));
  }

  const {
    userName = null,
    profilePhotoUrl,
    email,
  } = useSelector(({auth}) => auth?.login ?? {});
  return (
    <ul className={`nav navbar-nav navbar-nav-user float-right ${className}`}>
      <li className="nav-item">
        <div className="nav-link cursor-pointer" onClick={toggleTheme}>
          {theme === 'dark' ? <Sun/> : <Moon/>}
        </div>
      </li>
      <div className="py-2">
        {/*<div className="py-25">*/}
        {/*  <LanguageSwitcher/>*/}
        {/*</div>*/}
      </div>
      {/*<Notifications />*/}
      <Chats/>
      <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
          <div className="user-nav d-sm-flex d-none">
            <span className="user-name text-bold-600">
              {userName}
            </span>
            {email && <span className="user-name text-muted">
              {email}
            </span>}
            {/*<span className="user-status">Available</span>*/}
          </div>
          <span data-tour="user">
            <img
              src={profilePhotoUrl ?? 'https://api.sydig.com/images/default/shipper.png'}
              className="round"
              height="40"
              width="40"
              alt="U"
            />
          </span>
        </DropdownToggle>
        <UserDropdown {...props} />
      </UncontrolledDropdown>
    </ul>
  );
}
export default NavbarUser
