import React, {useState} from "react";
import PropTypes from "prop-types";
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,} from "reactstrap";
import {Link} from "react-router-dom";
import Sticky from "react-sticky-fill";
import {route} from "app/config/urls/routes";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import logoBlack from "@assets/img/logo/logo-black.svg";
import logoWhite from "@assets/img/logo/logo-white.svg";

const Item = ({text, route, className}) => (
  <NavItem>
    <NavLink
      tag={Link}
      to={route}
      className={`btn p-1 font-weight-bold ${className}`}
    >
      {text}
    </NavLink>
  </NavItem>
);

Item.propTypes = {
  text: PropTypes.string,
  route: PropTypes.string,
  className: PropTypes.string,
};

const PublicNavbar = ({transparent = false}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {token} = useSelector(({auth}) => auth.login);

  const toggle = () => setIsOpen(!isOpen);
  const isTransparent = transparent && !isOpen;
  const color = !isTransparent ? 'text-primary' : 'text-white'

  const {t} = useTranslation();

  return (
    <Sticky className="zindex-4">
      <Navbar light={!isTransparent} dark={isTransparent} className={isTransparent ? 'bg-transparent' : ''} expand="md">
        <NavbarBrand to="/" tag={Link}>
          {isTransparent && (
            <>
              <img src={logoWhite} alt="sydig logo" style={{height: '40px'}}/>
            </>
          )}
          {!isTransparent && <img src={logoBlack} alt="sydig logo"/>}
        </NavbarBrand>
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar className="w-100 mb-2 mb-md-0">
            <Item
              text="Firmy"
              route={route['public.company']}
              className={`btn px-2 ${color} ml-md-2 my-1 my-md-0`}
            />
            <Item
              text="Ogłoszenia"
              route={route['public.poster']}
              className={`btn px-2 ${color} ml-md-1`}
            />
            <div className="ml-auto mr-1">
              {/* <LanguageSwitcher /> */}
            </div>
            {token ? (
              <Item
                text="Panel"
                route={route['pc.availableVehicles']}
                className="btn-primary px-2 ml-auto text-white"
              />
            ) : (
              <>
                <Item
                  text="Rejestracja"
                  route={route['auth.register']}
                  className="btn-primary px-2 text-white my-1 my-md-0"
                />
                <Item
                  text={t("auth.login")}
                  route={route['auth.login']}
                  className="btn-primary px-2 text-white ml-md-1"
                />
              </>
            )}
          </Nav>
        </Collapse>
        <NavbarToggler onClick={toggle} className="ml-auto"/>
      </Navbar>
    </Sticky>
  );
};

PublicNavbar.propTypes = {
  transparent: PropTypes.bool,
};

export default PublicNavbar;
