import React from 'react';
import {UncontrolledTooltip} from "reactstrap";
import useMoment from "app/hooks/useMoment";

export const FromNow = ({id, time}) => {
  const moment = useMoment()
  return (
    <span id={id}>
      {moment(time)?.fromNow()}
    </span>
  );
};

export const FromNowTooltip = ({id, time, className, component = FromNow, ...props}) => {
  const moment = useMoment()
  if (!time) return null;
  return (
    <>
      {component({id, time, ...props})}
      <UncontrolledTooltip target={id} {...props}>
        {moment(time)?.format('DD-MM-YYYY, HH:mm:ss')}
      </UncontrolledTooltip>
    </>
  );
}

export const FullTimeTooltip = ({time, deleteIcon, target, children, tooltipOpen, toggle, ...props}) => {
  const moment = useMoment()
  return (
    <>
      {children}
      <UncontrolledTooltip
        isOpen={tooltipOpen}
        toggle={toggle}
        autohide={false}
        target={target}
        {...props}
      >
        {[time && moment(time)?.format('DD-MM-YYYY, HH:mm:ss'), deleteIcon]}
      </UncontrolledTooltip>
    </>
  );
}
