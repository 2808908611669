import React from 'react';
import {InputPureField} from "components/form/pure/InputPure_Field";
import {Paperclip, Send, XCircle} from "react-feather";
import {actionSetNewTmpMessage} from "app/redux/chats&notifications/chatActions/actionSetNewTmpMessage";
import {useMessagesHub} from "app/hooks/signalR/useMessagesHub";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {useSelectChatInput} from "app/redux/chats&notifications/selectors/selectChatInput";
import {EmojiPicker} from './EmojiPicker';
import {MessageFileInput} from './MessageFileInput';
import {UncontrolledTooltip} from 'reactstrap';
import {useTranslation} from "react-i18next";
import {useChatContext} from "views/pages/companyPanel/conversations/View_Conversation/components/chats/ChatContext";
import {actionSetNewTmpFile} from "app/redux/chats&notifications/chatActions/actionSetNewTmpFile";
import getBase64 from "app/utility/form/getBase64";

const isLoading = false

export const MessageInput = () => {
  const {t} = useTranslation()
  const activeChatId = useChatContext()
  const {newMessage, fileMessage} = useSelectChatInput();
  const dispatch = useDispatch();

  const [, {sendConversationMessage, sendConversationFile}] = useMessagesHub();

  const handleClickSendMessage = async () => {
    const chatId = parseInt(activeChatId)

    try {
      if (fileMessage) {
        const file = await getBase64(fileMessage)
        if (!file) throw Error()
        const data = {
          file,
          fileName: fileMessage?.name
        }

        await sendConversationFile(chatId, data);
        return
      }

      const msg = newMessage.trim();
      if (!msg) return

      await sendConversationMessage(chatId, msg);
    } catch (e) {
      toast.error(t('message.sendError'))
    }
  }

  const handleSetMessage = (message) => {
    dispatch(actionSetNewTmpMessage(activeChatId, message));
  }

  const handleClearFile = () => {
    dispatch(actionSetNewTmpFile(activeChatId, null));
  }

  const addEmoji = (e, emoji) => {
    //console.log(emoji)
    dispatch(actionSetNewTmpMessage(activeChatId, newMessage + emoji.emoji));
  }

  return (
    <div className="position-relative align-items-center d-flex w-100 pb-0 p-50">
      {!fileMessage && (
        <>
          <MessageFileInput/>
          <div className="flex-grow-1">
            <InputPureField
              onChange={handleSetMessage}
              onEnter={handleClickSendMessage}
              value={newMessage ?? ''}
              disabled={isLoading}
              className="m-0 my-auto"
              autoComplete="off"
              placeholder="Aa"
            />
          </div>
          <EmojiPicker onSelect={addEmoji}/>
        </>
      )}
      {fileMessage && (
        <>
          <UncontrolledTooltip target="messageFileCancelBtn">
            {t('generic.cancel')}
          </UncontrolledTooltip>
          <div className="btn-slink p-50 btn btn-link px-50" id="messageFileCancelBtn" onClick={handleClearFile}>
            <XCircle size={20}/>
          </div>
          <div className="my-auto mr-auto p-1 small font-weight-bold d-flex flex-row align-items-center">
            <Paperclip size={12} className="mr-1"/>
            {fileMessage.path}
          </div>
        </>
      )}
      <UncontrolledTooltip target="messageSendBtn">
        {t('message.send')}
      </UncontrolledTooltip>
      <div className={`btn-slink p-50 btn btn-link ${isLoading ? 'disabled' : ''}`} id="messageSendBtn"
           onClick={handleClickSendMessage}>
        <Send size={20}/>
      </div>
    </div>
  );
}
