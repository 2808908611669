import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/drivers`;

export const driversRoutes = {
  'pc.employee.driver': `${prefix}`,
  'pc.employee.driver.add': `${prefix}/add`,
  'pc.employee.driver.show': (id = ':employeeId') => `${prefix}/${id}`,
  'pc.employee.driver.edit': (id = ':employeeId') => `${prefix}/${id}/edit`,
};
