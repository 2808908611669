import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import {FullTimeTooltip} from "components/date/FromNow";
import styled from "styled-components";
import {Trash2} from 'react-feather';
import {useMessagesHub} from '../../../../../../../app/hooks/signalR/useMessagesHub';
import {useModal} from 'app/hooks/useModal';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {ImageMessage} from './messageTypes/ImageMessage';
import {FileMessage} from './FileMessage';
import Linkify from 'react-linkify';

export const StyledMessage = styled.div`
    border-bottom-left-radius: ${({isMine}) => !isMine && '0 !important'};
    border-bottom-right-radius: ${({isMine}) => !!isMine && '0 !important'};
    background-color:  ${({isMine, theme}) => isMine ? '#436eb3' : (theme?.theme === 'dark' ? '#323752' : '#eeeeee')};
    color:  ${({isMine, theme}) => isMine ? '#fcfcfc' : (theme?.theme === 'dark' ? '#eeeeee' : '#000')};
    display: inline-flex;
    word-break: break-word;
`;

const StyledMessageLink = styled.a`
  &:link {
    text-decoration: underline;
    color: white;
  }

  &:visited {
    text-decoration: underline;
    color: white;
  }

  &:hover {
    text-decoration: underline;
    color: white;
  }

  &:active {
    text-decoration: underline;
    color: white;
  }
`;

const messageLink = (id) => (href, link) => <StyledMessageLink key={`${id}-${href}`} target="_blank"
                                                               rel="noopener noreferrer"
                                                               href={href}>{link}</StyledMessageLink>

const DeleteMessageModal = ({messageId, isOpen, closeModal, toggleTooltip, isMine}) => {
  const [, {deleteConversationMessage}] = useMessagesHub();

  const removeMessage = (isForAll) => () => {
    deleteConversationMessage(messageId, isForAll)
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      onClosed={toggleTooltip}
    >
      <ModalHeader toggle={closeModal}>
        Usuń wiadomość
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary m-25" onClick={removeMessage(false)}>DLA SIEBIE</button>
        </div>
        {isMine && <div className="d-flex justify-content-center">
          <button className="btn btn-primary m-25" onClick={removeMessage(true)}>DLA WSZYSTKICH</button>
        </div>}
      </ModalBody>
    </Modal>
  )
}

const DeleteIcon = ({messageId, toggle, isMine}) => {

  const [isOpenModal, openModal, closeModal] = useModal();

  const handleClickRemoveIcon = () => {
    openModal();
  }
  return (<>
    <Trash2 size={14} className="ml-25 cursor-pointer" onClick={handleClickRemoveIcon}/>
    <DeleteMessageModal
      messageId={messageId}
      isOpen={isOpenModal}
      closeModal={closeModal}
      toggleTooltip={toggle}
      isMine={isMine}
    />
  </>);
}


export const Message = ({
                          isMine,
                          profilePhotoUrl,
                          message,
                          id,
                          fileUrl,
                          createdAt,
                          messageType,
                          avatarSize,
                          getSingleMessage,
                        }) => {
  const {chatId} = useParams();
  const [isOpenTooltip, setIsOpenTooltip] = useState();

  const toggle = () => {
    setIsOpenTooltip(!isOpenTooltip)
  }

  return (
    <div className="my-25">
      <div
        className={`col-10 col-sm-8 col-md-7 align-items-end d-flex ${isMine ? 'ml-auto text-right justify-content-end' : 'mr-auto'}`}>
        {!isMine && (
          <Avatar img={profilePhotoUrl} size={avatarSize} classNameOuter="mt-auto mr-2"/>
        )}
        <FullTimeTooltip
          target={`tooltip-${chatId}-${id}`}
          time={createdAt}
          placement={isMine ? 'left' : 'right'}
          // deleteIcon={
          //   <DeleteIcon
          //     key={id}
          //     toggle={toggle}
          //     messageId={id}
          //     isMine={isMine}
          //   />
          // }
          tooltipOpen={isOpenTooltip}
          toggle={toggle}
          flip={false}
        >

          {
            messageType?.id === 0
              ? (
                <StyledMessage
                  isMine={isMine}
                  className="rounded-lg"
                  id={`tooltip-${chatId}-${id}`}
                >
                  <div className="p-50">
                    <Linkify
                      componentDecorator={messageLink(id)}
                    >
                      {message}
                    </Linkify>
                  </div>
                </StyledMessage>
              ) : (
                messageType?.id === 1
                  ? (
                    <div className={`col-10 col-sm-8 col-md-6 px-0`} id={`tooltip-${chatId}-${id}`}>
                      <ImageMessage id={`tooltip-${chatId}-${id}`} fileUrl={fileUrl} messageId={id}
                                    messageType={messageType}/>
                    </div>
                  ) : (
                    <StyledMessage
                      isMine={isMine}
                      className="rounded-lg"
                      id={`tooltip-${chatId}-${id}`}
                    >
                      <div className="p-50">
                        <FileMessage message={message} fileUrl={fileUrl} messageId={id} messageType={messageType}/>
                      </div>
                    </StyledMessage>

                  )
              )
          }
        </FullTimeTooltip>
      </div>
    </div>
  );
}
