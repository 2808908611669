import styled from "styled-components";
import {useSelectChatHeader} from "app/redux/chats&notifications/selectors/selectChatHeader";
import {useDispatch} from "react-redux";
import {actionCloseChatbox, actionOpenChatbox} from "app/redux/chats&notifications/chatActions/actionChatbox";
import Avatar from "components/@vuexy/avatar/AvatarComponent";
import {X} from "react-feather";
import React from "react";

const EmployeeName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  white-space: nowrap;
`
const HiddenChatbox = ({chatId}) => {
  const {employee} = useSelectChatHeader(chatId);
  const dispatch = useDispatch()

  const handleClose = (e) => {
    e.stopPropagation()
    dispatch(actionCloseChatbox(chatId))
  }
  const handleOpen = () => {
    dispatch(actionOpenChatbox(chatId))
  }

  return (
    <a className="d-flex align-items-center cursor-pointer px-25 bg-theme bg-theme-secondary-hover rounded"
       onClick={handleOpen}>
      <Avatar imgHeight={20} imgWidth={20} img={employee?.photoUrl}/>
      <EmployeeName className="pl-25 font-weight-bold">
        {employee?.name ?? ''}
      </EmployeeName>
      <div className="ml-auto btn btn-link p-25 bg-theme-secondary-hover" onClick={handleClose}>
        <X size={20}/>
      </div>
    </a>
  );
}

export default HiddenChatbox
