import {LOGIN_WITH_EMAIL, LOGOUT, SET_LANGUAGE,} from "app/redux/actiontypes/auth/types";

const initialState = () => ({
  userRoles: [],
  userId: null,
  userName: null,
  email: null,
  token: null,
  profilePhotoUrl: null,
  language: {
    languageId: 2,
    languageCode: "gb",
  },
  companyId: null,
  hasLimitedAccess: true,
  accesses: [],
});

export const login = (state = initialState(), {type, payload}) => {
  switch (type) {
    case LOGIN_WITH_EMAIL: {
      const {
        userId,
        token,
        userRoles,
        userName,
        email,
        profilePhotoUrl,
        language,
        companyId,
        hasLimitedAccess,
        accesses,
      } = payload;
      return {
        ...state,
        userId,
        userName,
        email,
        token,
        userRoles,
        profilePhotoUrl,
        language,
        companyId,
        hasLimitedAccess,
        accesses,
      };
    }

    case LOGOUT: {
      return initialState();
    }

    case SET_LANGUAGE: {
      const {languageId, languageCode} = payload;
      return {
        ...state,
        language: {
          languageCode,
          languageId,
        },
      };
    }

    case "persist/REHYDRATE": {
      return {
        ...initialState(),
        ...state,
      };
    }

    // case "CHANGE_ROLE": {
    //   return { ...state, roles: payload }
    // }

    default: {
      return state;
    }
  }
};
