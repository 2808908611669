import {COMPANY_PANEL} from "app/config/urls/prefixes";
import React from "react";

const prefix = `${COMPANY_PANEL}/company-groups`;

// pc = panel.company

export const companyGroupRoutes = {
  'pc.companyGroup': `${prefix}`,
  'pc.companyGroup.add': `${prefix}/add`,
  'pc.companyGroup.show': (id = ':companyGroupId') => `${prefix}/${id}`,
  'pc.companyGroup.edit': (id = ':companyGroupId') => `${prefix}/${id}/edit`,
}
