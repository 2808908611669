import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "index",
    title: "Strona główna",
    type: "item",
    icon: <Icon.Home size={20}/>,
    navLink: "/",
  },
  {
    id: "functions",
    title: "Funkcjonalności",
    type: "item",
    icon: <Icon.Sliders size={20}/>,
    navLink: "/page2",
  },
  {
    id: "pricing",
    title: "Cennik",
    type: "item",
    icon: <Icon.DollarSign size={20}/>,
    navLink: "/page2",
  },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <Icon.Info size={20}/>,
    navLink: "/page2",
  },
  {
    id: "rules",
    title: "Regulamin",
    type: "item",
    icon: <Icon.List size={20}/>,
    navLink: "/page2",
  },
  {
    id: "contact",
    title: "Kontakt",
    type: "item",
    icon: <Icon.Mail size={20}/>,
    navLink: "/page2",
  }
]

export default horizontalMenuConfig
