import {COMPANY_PANEL} from "app/config/urls/prefixes";

const prefix = `${COMPANY_PANEL}/invoices`;

//pc = panel.company

export const invoiceRoutes = {
  'pc.invoice': `${prefix}`,
  'pc.invoice.add': `${prefix}/add`,
  'pc.invoice.show': (id = ':invoiceId') => `${prefix}/${id}`,
  'pc.invoice.edit': (id = ':invoiceId') => `${prefix}/${id}/edit`,
}
