import {createPlaceElement} from "app/redux/editOrder/utils/createPlaceElement";

export const setAllPlaces = (state, {orderPlaces: passedOrderPlaces}) => {
  let orderPlaces = {};
  let orderPlacesKeys = []

  passedOrderPlaces.forEach(place => {
    if (!place?.id) return;
    orderPlaces[place.id] = createPlaceElement(place);
    orderPlacesKeys.push(place.id);
  });

  return {
    ...state,
    orderPlaces,
    orderPlacesKeys,
  };
}
